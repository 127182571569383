import Cookies from 'js-cookie'
import { Sdk } from '../Sdk'

export const getAvailableLanguages = () =>
  !Sdk.getConfig().env.isProduction
    ? Sdk.getConfig().variables.lang.dev
    : Sdk.getConfig().variables.lang.prod
  // generally, do not comment languages out, configure in index.tsx the available ones
  export const LANGUAGES = [
  {
    code: 'it',
    label: 'Italiano',
  },
  {
    code: 'en',
    label: 'English',
  },
  {
    code: 'es',
    label: 'Castellano',
  },
  // {
  //   code: 'ca',
  //   label: 'Català',
  // },
  // {
  //   code: 'pt',
  //   label: 'Português',
  // },
  //TODO make LANGUAGES a function in order to enable strict SDK check on getAvailableLanguages
].filter((lang) => getAvailableLanguages().includes(lang.code))

export const DEFAULT_LANGUAGE = 'en'

export const isLocalhost = () =>
  Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
      ),
  )

const isIpv4 = () =>
  window.location.hostname.match(
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(:[0-9]+)?$/,
  )

const isDevEnvironment = () =>
  window.location.hostname.match(/www-.*\.evenfi\.dev/)

// DISABLED REDIRECT ON CHANGE LANGUAGE
// const excludeLangRedirect = () =>
//   isLocalhost() || isIpv4() || isDevEnvironment()
const excludeLangRedirect = () => true

export const setCurrentLang = (lang, refUrl?) => {
  let currentUrl = window.location.href
  if (refUrl) {
    currentUrl = refUrl
  }
  // Avoid setting a non-existing language
  lang = getAvailableLanguages().indexOf(lang) > -1 ? lang : DEFAULT_LANGUAGE
  const prevLang = getCookieLang()
  setCookieLang(lang)
  const currentUrlLang = getURLLang() || DEFAULT_LANGUAGE

  if (!excludeLangRedirect() && currentUrlLang !== lang) {
    return getLangFullUrl(lang, currentUrl)
  }
  if (excludeLangRedirect() && prevLang !== lang) {
    return window.location.href
  }

  return null
}

export const setCurrentLangAndReload = (lang) => {
  const newUrl = setCurrentLang(lang)
  if (newUrl) {
    if (newUrl === window.location.href) {
      window.location.reload()
    } else {
      window.location.replace(newUrl)
    }
  }
}

const getCookieLang = () => {
  return getAvailableLanguages().includes(Cookies.get('lang'))
    ? Cookies.get('lang')
    : undefined
}

export const setCookieLang = (lang) =>
  Cookies.set('lang', lang, { domain: getBaseHostname() })

const getBrowserLang = () => {
  const getRootLang = (lang: string) =>
    lang?.includes('-') ? lang.split('-')[0] : lang
  const browserLang = (
    getRootLang(navigator.languages && navigator.languages[0]) ||
    getRootLang(navigator.language) ||
    //@ts-ignore
    getRootLang(navigator.userLanguage)
  )
    .toLowerCase()
    .substring(0, 2)

  return getAvailableLanguages().includes(browserLang) ? browserLang : undefined
}

export const getBestLang = () => {
  return getURLLang() || getCookieLang() || getBrowserLang() || DEFAULT_LANGUAGE
}

export const getBaseHostname = () => {
  const domain = window.location.hostname.split('.')
  while (getAvailableLanguages().indexOf(domain[0]) > -1) {
    domain.shift()
  }

  return domain.join('.')
}

export const getLangHostname = (lang) => {
  const domain = window.location.hostname.split('.')
  while (getAvailableLanguages().indexOf(domain[0]) > -1) {
    domain.shift()
  }

  if (lang !== DEFAULT_LANGUAGE) {
    domain.unshift(lang)
  }

  return domain.join('.')
}

export const getLangFullUrl = (lang, currentUrl = null) => {
  let refUrl = window.location.href
  if (currentUrl) {
    refUrl = currentUrl
  }
  const langHostname = getLangHostname(lang)
  return refUrl
    .replace('www.', '')
    .replace(window.location.hostname, langHostname)
}

export const getURLLang = () => {
  const languagesNoEn = getAvailableLanguages().filter((l) => l !== 'en')

  const lang = window.location.hostname.split('.')[0]
  if (languagesNoEn.indexOf(lang) > -1) {
    return lang
  }

  return null
}
